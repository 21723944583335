<template>
    <div class="content_PressReleaseDetail">
        
        <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#0FD8C9"
                v-if="isLoading"
        />


        <h1>{{ title }}</h1>
        <p class="articleDate">Article Date {{ customFormatter(articleDate) }}</p>
        
        <span v-html="content"></span>

        <div v-if="boilerPlateVisible">
            <p>The Ohio Lottery has contributed more than $33 billion to education since 1974. For more information about the Ohio Lottery and its contribution to education, visit <a href="/supportingeducation">www.ohiolottery.com/supportingeducation</a></p>
            <p style="text-align:center;">###</p>
        </div>

    </div>
</template>

<script>
    import moment from 'moment';
    import { HollowDotsSpinner } from 'epic-spinners';

    export default {
        props: ['articleId'],
        components: {
            HollowDotsSpinner
        },
        data: function () {
            return {
                articleIdDetail: this.articleId,
                title: '',
                content: '',
                articleDate: '',
                isLoading: false,
                boilerplateDate: '4/4/2024',
                boilerPlateVisible: true
            }
        },
        mounted(){
            this.getToken()
            this.isLoading = true;
        },
        methods:{
            customFormatter(date) {
                return moment(date).format('M/D/YYYY');
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });
            },
            fetchData(token) {
                let apiToken = token,
                    urlProtocol = this.getProtocol(),
                    id = this.articleIdDetail;
                    
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/Article/GetById/"+id, {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    let res = json.data;
                    this.title = res.title;
                    this.content = res.content;
                    this.articleDate = res.date;

                    let cutoffdate = new Date(this.boilerplateDate);
                    this.articleDate = new Date(res.date);
                    // console.log(cutoffdate, this.articleDate);

                    if(this.articleDate < cutoffdate) {
                        this.boilerPlateVisible = false;
                    }

                    this.isLoading = false;

                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;

                });
            }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>
    .articleDate {
        font-size: 1.4rem;
    }

</style>