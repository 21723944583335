<template>
    <div>

        <PollQuestion></PollQuestion>
    </div>
</template>

<script>
    import PollQuestion from './PollQuestion.vue';

    export default {
        components: {
            PollQuestion
        },
        data: function () {
            return {
            }
        },
        mounted: function () {
        },
        methods: {
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>

</style>
