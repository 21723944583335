<template>
    <div>
        <!-- <section class="module kycGamesMsg" v-if="!kyc">
            <span><h4>Please verify your ID.</h4><p>You are required to verify your identity before redeeming points in MyLotto Rewards. This helps us prevent unauthorized access to your account and protect your personal information.</p><p>You may only demo the Games for Prizes until you are verified.</p><div><a href="/mylotto-rewards/my-account/verify-my-identity" class="button btn-primary" target="_blank">Verify My ID</a>.</div></span>
        </section> -->
        <h3 v-if="empty === true">There are currently no active games. Please check back later.</h3>
        <section v-if="games.length">
            <div class="accordianItem cf" id="js_add_games">
                <div v-for="game in games" :key="game.id" class="cleafix interactiveGame-box" :id="game.id">
                    <span>

                        <div class="interactiveGame-gridItem">
                            <!-- FOR TESTING -->
                            <!-- <img :src="'https://solutions-uat.ohiolottery.com' + game.mainImageUrl" @mouseover="hover(game.id)" @mouseleave="exit(game.id)"> -->
                            <!-- FOR PRODUCTION -->
                            <img :src="game.mainImageUrl" @mouseover="hover(game.id)" @mouseleave="exit(game.id)">
                            <!-- <div class="mainImageUrl" :id="game.cssID" @mouseover="hover(game.id)" @mouseleave="exit(game.id)" /> -->
                            <span class="interactiveGameTitle">{{game.name}}</span>
                            <div class="ig-btns" :ref="'button' + game.id" @mouseover="hover(game.id)" @mouseleave="exit(game.id)">

                                <a class="interactivePlay-btn red_btn" :href="'/MyLotto-Rewards/Redemption-Central/Interactive-Games/IGTest?id=' + game.id + ''" v-if="auth && !kycMode">PLAY</a>
                                <a class="interactivePlay-btn red_btn" :href="'/MyLotto-Rewards/Redemption-Central/Interactive-Games/IGTest?id=' + game.id + ''" v-if="auth && kycMode && isKYCVerified">PLAY</a>
                                <a class="interactivePlay-btn red_btn" @click="handleClick($event)" v-if="auth && kycMode && !isKYCVerified">PLAY</a>
                                <a class="interactiveDemo-btn red_btn" :href="'/MyLotto-Rewards/Redemption-Central/Interactive-Games/IGTest?id=' + game.id + '&demo=true'">DEMO</a>

                            </div>
                            <span class="interactiveGameText"></span>
                            <span class="interactiveGamePoints">
                                <strong></strong>
                            </span>
                        </div>

                        <!-- <a :href="'/MyLotto-Rewards/Redemption-Central/Interactive-Games/IGTest?id=' + game.id + demo" class="interactiveGame-gridItem">
                            <div class="mainImageUrl" :id="game.cssID" @mouseover="hover(game.id)" @mouseleave="exit(game.id)" />
                            <div class="ig-btns" :ref="'button' + game.id" @mouseover="hover(game.id)" @mouseleave="exit(game.id)">

                                <div class="interactivePlay-btn red_btn" @mouseover="noDemo" v-if="auth && !kycMode">PLAY</div>
                                <div class="interactivePlay-btn red_btn" @mouseover="noDemo" v-if="auth && kycMode && isKYCVerified">PLAY</div>
                                <div class="interactivePlay-btn red_btn" @mouseover="noDemo" @click="handleClick($event)" v-if="auth && kycMode && !isKYCVerified">PLAY</div>
                                <div class="interactiveDemo-btn red_btn" @mouseover="addDemo">DEMO</div>

                            </div>
                            <span class="interactiveGameTitle">{{game.name}}</span>
                            <span class="interactiveGameText"></span>
                            <span class="interactiveGamePoints">
                                <strong></strong>
                            </span>
                        </a> -->

                        <!-- <a :href="'/MyLotto-Rewards/Redemption-Central/Interactive-Games/IGTest?id=' + game.id + demo" class="interactiveGame-gridItem" v-if="kycMode && isKYCVerified">
                            
                            <div class="mainImageUrl" :id="game.cssID" @mouseover="hover(game.id)" @mouseleave="exit(game.id)" />
                            <div class="ig-btns" :ref="'button' + game.id" @mouseover="hover(game.id)" @mouseleave="exit(game.id)">

                                <div class="interactivePlay-btn red_btn" @mouseover="noDemo" v-if="auth">PLAY</div>
                                <div class="interactiveDemo-btn red_btn" @mouseover="addDemo">DEMO</div>

                            </div>
                            <span class="interactiveGameTitle">{{game.name}}</span>
                            <span class="interactiveGameText"></span>
                            <span class="interactiveGamePoints">
                                <strong></strong>
                            </span>
                        </a>

                        <a @click="handleClick" class="interactiveGame-gridItem" v-if="kycMode && !isKYCVerified">
                           
                            <div class="mainImageUrl" :id="game.cssID" @mouseover="hover(game.id)" @mouseleave="exit(game.id)" />
                            <div class="ig-btns" :ref="'button' + game.id" @mouseover="hover(game.id)" @mouseleave="exit(game.id)">

                                <div class="interactivePlay-btn red_btn" @mouseover="noDemo" v-if="auth">PLAY</div>
                                <div class="interactiveDemo-btn red_btn" @mouseover="addDemo">DEMO</div>

                            </div>
                            <span class="interactiveGameTitle">{{game.name}}</span>
                            <span class="interactiveGameText"></span>
                            <span class="interactiveGamePoints">
                                <strong></strong>
                            </span>
                        </a> -->

                    </span>
                </div>
            </div>
        </section>
        <div id="modal-kyc" class="reveal modal-kyc reveal-modal" data-reveal data-close-on-click="false" data-close-on-esc="false">
            <h2>Please verify your account</h2>
            <p>We have updated security for MyLotto Rewards. <strong>Please note that you will not be able to submit tickets or redeem existing points until you have completed the identity verification process.</strong></p>
            <div class="modal-kyc_actions">
                <a @click="goVerify" class="button btn-primary">Verify My Id</a>
                <button type="button" class="button btn-primary" data-close="modal-kyc">I'll do it later</button>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        data: function() {
            return {
                token: true,
                games: [],
                demo: '',
                auth: false,
                kyc: true,
                kycStatic: '',
                user: {},
                id: '',
                kycMode: process.env.VUE_APP_KYCMODE,
                isKYCVerified: false,
                empty: false
            }
        },
        mounted: function() {
            this.getToken()
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.auth = this.isUserAuthed();

                    if (this.auth) {
                        this.user = this.$cookies.get('apiToken');
                        this.id = this.user.user.userId;
                    }
                })
                .then(() => {
                    this.getKYCVerified();
                })
                .then(() => {
                    // this.token = token;
                    this.getGames(this.token);
                });
            },
            // add_game_toPage (game) {
            //     var target = document.getElementById('js_add_games');
            //     var div = document.createElement('div');
            //     div.className = 'clearfix interactiveGame-box';
            //     div.id = game.Id;
            //     if (document.querySelector('#userIdentifier')) {
            //     div.innerHTML = '<a href="/MyLotto-Rewards/Redemption-Central/Games-for-Prizes/interactive-game?id=' + game.Id + '" class="interactiveGame-gridItem"><img src="' + game.MainImageUrl + '"><span class="ig-btns"><span class="interactivePlay-btn red_btn">PLAY</span><span class="interactiveDemo-btn red_btn" onClick="addDemo(event, ' + game.Id + ')">DEMO</span></span><span class="interactiveGameTitle">' + game.Name + '</span><span class="interactiveGameText"></span><span class="interactiveGamePoints"><strong></strong></br></span></a>';
            //     } else {
            //     div.innerHTML = '<a href="/MyLotto-Rewards/Redemption-Central/Games-for-Prizes/interactive-game?id=' + game.Id + '" class="interactiveGame-gridItem"><img src="' + game.MainImageUrl + '"><span class="ig-btns"><span class="interactivePlay-btn red_btn">PLAY</span><span class="interactiveDemo-btn red_btn" onClick="addDemo(event, ' + game.Id + ')">DEMO</span></span><span class="interactiveGameTitle">' + game.Name + '</span><span class="interactiveGameText"></span><span class="interactiveGamePoints"><strong></strong></br></span></a>>';
            //     }
            //     target.appendChild(div);
            // },
            // addDemo(e, id) {
            //     e.preventDefault();
            //     var aTag = document.getElementById(id).getElementsByTagName('a');
            //     window.location.href = aTag[0].href += '&demo=true';
            // },
            // unregDemo(e, id) {
            //     e.preventDefault();
            //     var aTag = '/MyLotto-Rewards/Redemption-Central/Games-for-Prizes/interactive-game?id=' + id;
            //     window.location.href = aTag + '&demo=true';
            // },
            getGames(token) {
                let apiToken = token,
                    url = process.env.VUE_APP_APIURL+'/1.0/Games/InteractiveGames/GetAvailableGames?pagenumber=0&itemsPerPage=100';

                return fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + apiToken
                    }
                })
                .then(response => response.json())
                .then(json => {
        
                    let data = json.data.games,
                        games = [];

                    for (let i = 0; i < data.length; i++) {
                        let game = { ...data[i] };

                        if (game.name.indexOf('Connectr_') > -1) {
                            game.cssID = 'connectr';
                        } else if (game.name.indexOf('Hardwood') > -1) {
                            game.cssID = 'hardwood';
                        } else if (game.name.indexOf('Mazr') > -1) {
                            game.cssID = 'mazr';
                        } else if (game.name.indexOf('Mirrorball') > -1) {
                            game.cssID = 'mirrorball';
                        } else if (game.name.indexOf('Mix') > -1) {
                            game.cssID = 'mix';
                        } else if (game.name.indexOf('Spinzy') > -1) {
                            game.cssID = 'spinzy';
                        }

                        games.push(game);
                    }

                    this.games = games;

                    if (this.auth) {
                        this.user = this.$cookies.get('apiToken');
                        this.id = this.user.user.userId;
                        // this.kyc = this.user.user.KYCVerified;
                        // console.log("KYC: " + this.kyc);
                    }

                    if (this.games.length < 1) {
                        console.log("Empty")
                        this.empty = true;
                    }
                })
                .catch(error => {
                    console.log(this.errorMessage = error);
                })
            },
            hover(id) {
                console.log("Hover");
                this.$refs['button' + id][0].style.display = 'block';
            },
            exit(id) {
                console.log("Exit");
                this.$refs['button' + id][0].style.display = 'none';
            },
            noDemo() {
                this.demo = '';
            },
            addDemo() {
                this.demo = '&demo=true';
            },
            getKYCVerified() {
                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUserKYCStatus/?userId=' + this.id;
                
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + this.token
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json.data);
                    this.isKYCVerified = json.data.userIsVerified;
                    // this.verifyLink = json.data.kycVerificationLink;
                    // this.kyc = true;

                    if (!this.kycMode) {
                        this.isKYCVerified = true;
                    }

                    console.log(`KYC : ${this.isKYCVerified}`);
                    // console.log(`Link URL : ${this.verifyLink}`);
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            },
            handleClick(e) {
                e.preventDefault();
               //should only run when kycMode is true and isKYCVerified is false
               console.log("kycMode is true and isKYCVerified is false");
               this.openKYCModal();
            },
            openKYCModal() {
                let popup = new Foundation.Reveal($('#modal-kyc'));
                popup.open();
            },
            goVerify() {
                //set the return link into a cookie
                //go to verify page
                let goURL = window.location.origin + "/mylotto-rewards/my-account/verify-my-identity";
                this.$cookies.set("kycReturnUrl", JSON.stringify(window.location));
                window.location.assign(goURL);

            }
        }
    }
</script>
<style scoped>
    .kycGamesMsg {
        background-color: #F1EFEE;
        padding: 20px;
    }
</style>