<template>
    <section class="retailer-registration userRegFormWrap">
        <RegistrationFormDynamic :successurl="successUrl" :assignrole="assignrole"></RegistrationFormDynamic>
    </section>     
</template>

<script>
    import RegistrationFormDynamic from "./RetailerRegistrationForm_Dynamic.vue";

    export default {
        components: {
           RegistrationFormDynamic
        },
        data: function () {
            return {
                successUrl: '/eApplications/Registration/Thanks-for-Registering',
                assignrole: 1

            }
        },
        mounted: function () {
        },
        methods: {
        },
        computed: {
        },
    };
</script>

<style lang="scss" scoped>
input, select{
    height: auto;
    line-height: inherit;
}
.form-error-birth{
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #cc4b37;
    display: block;
}

.terms{
    overflow: auto;
    height: 200px;
    width: 100%;
    padding: 10px;
    margin: 0 0 10px;
    background: #fff;
    border: 1px solid #B9BDC3;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-clip: padding-box;
}
</style>