<template>
    <div class="full moolahProgess drawingList drawingListResponsive" v-if="moolah === true">       
        <h2>YOUR INVADERS FROM THE PLANET MOOLAH<sup>TM</sup> ENTRY STATUS</h2>
        <p><em>Enter four non-winning INVADERS FROM THE PLANET MOOLAH<sup>TM</sup> tickets to earn an entry. Each ticket is $5</em></p>
        
        <div class="moolahTickets">
            <div v-for="ticket in tickets" 
                :key="ticket.key"
                class="moolahTicketContainer">
                <img
                    :src="ticket.entryImage" 
                    :alt="ticket.ticketName + ' - Moolah Promotion'"
                    class="moolahTicketImage"
                />
            </div>
        </div>
        <p><em>2025 TM & © Light & Wonder, Inc. or one of its subsidiaries. Used under license.</em></p>


    </div>
</template>
<script>
    export default {
        data: function() {
            return {

                promotionID: '',
                promotionName: '',
                message: '',

                userID: '',
                counter: '',

                tickets: [
                    {
                        key: 1,
                        entry: '',
                        entryImage: '/getattachment/f5155a91-2025-43b1-bcb6-5c2e97e32d2e/InvadersMoolah_TicketArt_BW.png',
                        ticketName: "Invaders from the Planet Moolah",
                    },
                    {
                        key: 2,
                        entry: '',
                        entryImage: '/getattachment/f5155a91-2025-43b1-bcb6-5c2e97e32d2e/InvadersMoolah_TicketArt_BW.png',
                        ticketName: "Invaders from the Planet Moolah",
                    },
                    {
                        key: 3,
                        entry: '',
                        entryImage: '/getattachment/f5155a91-2025-43b1-bcb6-5c2e97e32d2e/InvadersMoolah_TicketArt_BW.png',
                        ticketName: "Invaders from the Planet Moolah",
                    },
                    {
                        key: 4,
                        entry: '',
                        entryImage: '/getattachment/f5155a91-2025-43b1-bcb6-5c2e97e32d2e/InvadersMoolah_TicketArt_BW.png',
                        ticketName: "Invaders from the Planet Moolah",
                    }
                ],

                isMobile: true,
                entryData: [],
                moolah: true,
            }
        },
        mounted: function() {
            //console.log("Component loaded");
            this.getToken();
            this.checkIfMobile();
            window.addEventListener('resize', this.checkIfMobile);
            this.showMoolah();
        },
        computed: {},
        methods: {
            showMoolah() {
                let query = window.location.origin;

                const currentDate = new Date(),
                    moolahStart = new Date('10/8/2024'), //1/14/2025
                    moolahEnd = new Date('2/24/2025');

                if (query.indexOf('www') > -1 && (currentDate < moolahStart || currentDate > moolahEnd)) {
                    this.moolah = false;
                }
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                });

            },
            fetchData(token) {
                let apiToken = token;
                let userID = this.getLoggedInUserId();

                let params = new URLSearchParams(document.location.search);
                let promotionId;
                promotionId = params.get("id");

                if (promotionId == null) {
                    // promotionId = document.getElementById('PromotionID').value
                    let env = this.getCurrentEnvironment()
                    
                    promotionId = 46;
                    if (env == 'uat' || env == 'local'){
                        promotionId = 45;
                    } 
                    else if (env == 'dev' ){
                        promotionId = 39;
                    } 
                }

                return fetch(process.env.VUE_APP_APIPROTOCOL+process.env.VUE_APP_APIURL+"/1.0/SecondChance/Promotion/GetSecondChanceNotifications/"+userID+"/"+promotionId, {
                    headers: {
                        'Authorization': 'Bearer '+ apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var results = json.data;
                    this.entryData = results[0];

                    this.updateTicketsArray();
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            updateTicketsArray() {

                this.promotionID = this.entryData.promotionID;
                this.promotionName = this.entryData.promotionName;
                this.message = this.entryData.message;

                this.userID = this.entryData.secondaryData.userID;
                this.counter = this.entryData.secondaryData.counter;

                this.tickets[0].entry = this.entryData.secondaryData.entry1;
                this.tickets[0].entryImage  = this.entryData.secondaryData.entry1Image;

                this.tickets[1].entry = this.entryData.secondaryData.entry2;
                this.tickets[1].entryImage = this.entryData.secondaryData.entry2Image;

                this.tickets[2].entry = this.entryData.secondaryData.entry3;
                this.tickets[2].entryImage = this.entryData.secondaryData.entry3Image;

                this.tickets[3].entry = this.entryData.secondaryData.entry4;
                this.tickets[3].entryImage = this.entryData.secondaryData.entry4Image;
      
            },
            checkIfMobile() {
                this.isMobile = window.matchMedia("(max-width: 640px)").matches;
            }    
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.checkIfMobile);
        }
    }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
/*general styles*/
    .full.moolahProgess{
        margin:10px;
    }

    .full.moolahProgess h2{
        font-family: "Source Sans 3";
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        margin: 20px 0px 0px 5px;
        color: black;
    }

    .full.moolahProgess p{
        font-family: "Source Sans 3";
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20px;
        margin: 5px 5px;
    }
    .full.moolahProgess.drawingList{
        background-color: #fff;
        margin:0px;
        padding:0px;
    }
/*end general*/
    
/*Ticket status styling*/
   .moolahTickets { /*entire tickets container*/
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .moolahTicketContainer { /*individual ticket container*/
        position: relative;
        display: inline-block;
        width:25%;
        height:25%;
    }

    .full.moolahProgess p, .full.moolahProgess h2{
        text-align: center;
    }

    .moolahTicketImage{ /*individual ticket images*/
        width: 90%;
        height: 90%;
        object-fit: contain;
        object-position: top left;
        border-radius: 5px;
        margin: 6px 6px 15px 6px;
    }

    @media (max-width: 460px) {
        .moolahTickets { /*entire tickets container*/
            flex-wrap: wrap;
        }

        
        .moolahTicketContainer { /*individual ticket container*/
            width:50%;
            height:50%;
        }

    }


    @media (min-width: 641px) {
        .moolahTickets { /*entire tickets container*/
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        
        .moolahTicketContainer { /*individual ticket container*/
            width:20%;
            height:20%;
        }

        .full.moolahProgess p, .full.moolahProgess h2{
            text-align: left;
        }
    }

/*end ticket status*/
</style>